<template>
<!--  顶部-->
<div class="header">
  <div style="display: flex;align-items: center" >
    <!--PC端点击侧边栏-->
    <a-button type="primary"  title="展开或收缩扩展栏" @click="toggleCollapsed" class="menu">
      <div class="iconfont icon-outdent" v-if="isCollapse" />
      <div class="iconfont icon-indent" v-else />
    </a-button>
    <!--移动端点击侧边栏-->
    <a-button type="primary" title="跳转至其他页面"  @click="toggleMenu" class="menu-phone">
      <div class="iconfont icon-outdent" v-if="menuIsCollapse" />
      <div class="iconfont icon-indent" v-else />
    </a-button>
    <a-breadcrumb style="margin-left: 20px">
      <a-breadcrumb-item v-for="(menu,index) in routeList" :key="index">{{menu}}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>
  <a-dropdown :trigger="['click']">
    <!--电脑端头像显示-->
      <div class="user-info">
        <a-avatar alt="个人头像" :src="user.userInfo.avaror" class="icon" :size="40" :preview="false"  ></a-avatar>
        <div class="info">
          <div style="display: flex;justify-content: space-between;align-items: baseline">
            <span class="title">{{user.userInfo.nickname}}</span>
            <span class="role">管理员</span>
          </div>
          <div style="min-height: 25px;min-width: 120px;line-height: 25px">{{ dateTime }}</div>
        </div>
      </div>
    <!--移动端头像显示-->
    <template #overlay>
      <div class="person-context" :class="[component.darkModel ?  'dark' : 'light']">
        <div class="context-item">
          暗黑模式
          <a-switch v-model:checked="component.darkModel" size="small"/>

        </div>
        <div>
          主题颜色
          <ul style="display: flex;justify-content: space-between;margin: 8px 0">
            <li class="color" :class="[{'selected': component.selectThemeColor===item.themeColor}]" :key="index"  v-for="(item,index) in themeList" @click="changeTheme(item.themeColor)" :style="'background-color:'+item.themeColor+';border: 2px solid'+item.themeColor  ">
              <div class="color-item" :style="'background-color:'+item.themeColor"></div>
            </li>
          </ul>
        </div>

        <a-button @click="exit">注销登录</a-button>
      </div>
    </template>

  </a-dropdown>
    <!--是否确定退出-->
      <a-modal v-model:open="visible" :bodyStyle="{top:'30%'}" cancelText="取消" okText="确定" :closable="false" title="提示"  :width="300" @ok="confirmExit">
          是否确定退出？
      </a-modal>

  <a-drawer
      placement= "left"
      :closable="false"
      :mask="true"
      :maskClosable="true"
      :visible="menuIsCollapse"
      @close="menuIsCollapse=false"
      width="200"
      :bodyStyle="{padding:0}"
      :drawerStyle="{'background-color': darkModel ? '#141414 ' : '#fff'}"
  >
    <layout-menu :style="{'background-color': darkModel ? '#141414' : '#fff'}"></layout-menu>
  </a-drawer>
</div>
</template>

<script setup>
import moment from "moment";
import { componentStore } from '@/store/component';
import { defineComponent, ref } from 'vue';
import {reactive,onMounted,onBeforeMount} from 'vue';
import { storeToRefs } from 'pinia'
import logo from "@/static/img/logo-0.png"
import router from "@/router";
import {ConfigProvider} from "ant-design-vue";
import {userStore} from "@/store/user";
import layoutMenu from "@/component/layout-menu/layoutMenu"

    let dateTime = ref('');
    const component = componentStore();
    let {darkModel}=storeToRefs(component);
    const user =userStore();
    let {isCollapse} = storeToRefs(component);//侧边栏
    const {routeList} = storeToRefs(component);
    let menuIsCollapse = ref(false);
    let visible = ref(false);
      const themeList=[
      { themeColor:'#ff4d4f'},
      { themeColor:'#f0a23a'},
      { themeColor:'#ec488d'},
      { themeColor:'#a372e6'},
      { themeColor:'#27ae60'},
      { themeColor:'#1890ff'},
    ]
    onMounted(() => {
      //开启定时器
      getTime();
    })

    //定时器获取时间日期
    function getTime() {
      let timer = setInterval(() => {
        dateTime.value = moment().format("YYYY-MM-DD HH:mm:ss")
      }, 1000)
    }

    //PC端点击侧边栏
    function toggleCollapsed() {
      component.setIsCollapse();
    }

    //移动端点击侧边栏
    function toggleMenu() {
      menuIsCollapse.value = !menuIsCollapse.value
    }
    //修改主题颜色
    function changeTheme(color){
      component.setSelectThemeColor(color)

      const colorState = {
        primaryColor: color,
      };
      ConfigProvider.config({
        theme: colorState,
      });
     document.documentElement.style.setProperty('--primary-color', component.getSelectThemeColor);
    }
    function exit(){
      visible.value=true
    }
    //用户确定退出
    function confirmExit(){
        component.darkModel=false
        visible.value=false;
        user.setToken('');
        user.setUserMenu([]);
        router.replace("/login");
    }
    
</script>

<style scoped lang="scss">
/* 针对大屏幕设备（例如平板电脑） */
@media screen and (min-width: 768px) {
  /* 样式代码 */
  .menu-phone{
    display: none !important;
  }
}

/* 针对中等屏幕设备（例如手机） */
@media screen and (max-width: 767px) {
  /* 样式代码 */
  .menu-phone{
    display: none !important;

  }
  .user-info {
    width: 40px !important;
    .info {
      display: none;
    }
  }
}

/* 针对小屏幕设备（例如低端手机） */
@media screen and (max-width: 640px) {
  .menu{
    display: none;
  }
  .menu-phone{
    display: block !important;
  }

}
.header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .menu-phone{
    display: none;
  }

}
.user-info{
  display: flex;
  width: 220px;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  cursor:pointer;
  user-select: none;
  .info{
    min-width: 120px;
  }
.role{
  font-weight: 400;
  font-size: 12px;
}

.info{
  margin-left: 15px;
}
}
.person-context{
  width: 190px;
  border-radius: 5px;
  display: flex;
  font-size: 12px;
  flex-direction: column;
  padding: 5px 15px 10px 15px;
  .context-item{
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .color{
    width: 18px;
    height: 18px;
    list-style:none;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    caret-color: transparent;
    border-radius: 4px;
    .color-item{
      width: 10px;
      height: 10px;
    }
  }
  .selected{
    background-color: white !important;
  }

}
.light{
  background-color: white;
}
.dark{
  background-color: #555555;
  color: white;
}

</style>
