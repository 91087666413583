

<template>
    <a-table bordered :data-source="dataSource" :columns="columns" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
        <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'name'">
                <div class="editable-cell">
                    <div v-if="editableData[record.key]" class="editable-cell-input-wrapper">
                        <a-input v-model:value="editableData[record.key].name" @pressEnter="save(record.key)" />
                        <check-outlined class="editable-cell-icon-check" @click="save(record.key)" />
                    </div>
                    <div v-else class="editable-cell-text-wrapper">
                        {{ text || ' ' }}
                        <edit-outlined class="editable-cell-icon" @click="edit(record.key)" />
                    </div>
                </div>
            </template>
            <template v-else-if="column.dataIndex === 'operation'">
                <a-popconfirm
                        v-if="dataSource.length"
                        title="Sure to delete?"
                        @confirm="onDelete(record.key)"
                >
                    <a>Delete</a>
                </a-popconfirm>
            </template>
        </template>
    </a-table>
</template>

<script setup>
const columns = [
    {
        title: '文件名称',
        dataIndex: 'name',
        width: '30%',
    },
    {
        title: '文件大小',
        dataIndex: 'age',
    },
    {
        title: '最后一次修改时间',
        dataIndex: 'address',
        responsive: ['lg'],
    },
    {
        title: '操作',
        dataIndex: 'operation',
    },
];
</script>
<style scoped>

</style>