
<template>
    <div class="placeholding">组件放置区域</div>
</template>
<script setup>

</script>
<style scoped>
.placeholding{
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: var(--ant-primary-4);
}
</style>