import { defineStore } from 'pinia'

// 写法1
export const userStore = defineStore("user",{
    state: () => {
        return {
            //用户信息
            userInfo: {},
            token:'',
            userMenu:[],
            menu:[],
            //用户选中的仓库
            selectedValue:"",
            //用户仓库凭证
            voucherList:[{
                key:'',
            },{
                key:'',
            }]
        }
    },
    persist: {
        enabled: true,
        // 自定义持久化参数
        strategies: [
            {
                // 自定义key
                key: 'login_store',
                // 自定义存储方式，默认sessionStorage
                storage: localStorage,
                // 指定要持久化的数据，默认所有 state 都会进行缓存，可以通过 paths 指定要持久化的字段，其他的则不会进行持久化。
                paths: ['token'],
            },
            {
                // 自定义key
                key: 'login_menu',
                // 自定义存储方式，默认sessionStorage
                storage: localStorage,
                // 指定要持久化的数据，默认所有 state 都会进行缓存，可以通过 paths 指定要持久化的字段，其他的则不会进行持久化。
                paths: ['userMenu'],
            },
            {
                // 自定义key
                key: 'login_info',
                // 自定义存储方式，默认sessionStorage
                storage: localStorage,
                // 指定要持久化的数据，默认所有 state 都会进行缓存，可以通过 paths 指定要持久化的字段，其他的则不会进行持久化。
                paths: ['userInfo'],
            },
            {
                // 自定义key
                key: 'voucherList',
                // 自定义存储方式，默认sessionStorage
                storage: localStorage,
                // 指定要持久化的数据，默认所有 state 都会进行缓存，可以通过 paths 指定要持久化的字段，其他的则不会进行持久化。
                paths: ['voucherList'],
            },
            {
                // 自定义key
                key: 'selectedValue',
                // 自定义存储方式，默认sessionStorage
                storage: localStorage,
                // 指定要持久化的数据，默认所有 state 都会进行缓存，可以通过 paths 指定要持久化的字段，其他的则不会进行持久化。
                paths: ['selectedValue'],
            }
        ]
    },
    getters: {
        getUserInfo: (state) => {
            return state.userInfo
        },
        getToken:(state => {
            return state.token
        }),
        getUserMenu:(state)=>{
            return state.userMenu
        },
        getMenu:(state)=>{
            return state.menu
        },
        getVoucherList(state){
            return state.voucherList
        },
        getSelectedValue(state){
            return state.selectedValue
        },
    },
    actions: {
        setUserInfo(userInfo) {
            this.userInfo = userInfo
        },
        setToken(token){
            this.token=token
        },
        setUserMenu(userMenu){
            this.userMenu=userMenu
        },
        setMenu(menu){
            this.menu=this.formatMenu(menu)
        },
        setVoucherList(voucherList){
            this.voucherList=voucherList
        },
        setSelectedValue(selectedValue){
            this.selectedValue=selectedValue
        },
        formatMenu(menu){
           const menuList={
               path:'/index',
               name:'index',
               meta:{
                   isAuth: true
               },
               component:() => import('@/pages/index/index'),
               children:[]
           }
            //初始化子路由参数
            function setMenuItem(item){
                const menu = {}
                menu.path = item.path;
                menu.name = item.menuName;
                menu.meta = {isAuth: true}; //配置需要进行权限认证
                menu.component = () => import(`@/${item.component}`)
                return menu;
            }
            //递归遍历
            function buildMenuItems(items){
                items.forEach(item => {
                    const menuItem = setMenuItem(item);
                    menuList.children.push(menuItem);
                    if (item.children.length > 0) {
                        buildMenuItems(item.children);
                    }
                });
            }
            buildMenuItems(menu);
            return menuList
        },
    }

})
