<template>
  <a-config-provider   
    :theme="{
      algorithm: [component.darkModel ? theme.darkAlgorithm : theme.defaultAlgorithm],
      token: {
        colorPrimary: component.selectThemeColor,
        borderRadius: 2
      },
    }" >
    <div id="app" :class=" [darkModel ? 'theme-dark':'theme-light'  ]">
      <router-view></router-view>
    </div>
  </a-config-provider>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import {componentStore} from "@/store/component";
import {onMounted} from "vue";
import {ConfigProvider, theme} from "ant-design-vue";
    const component=componentStore();
    let {darkModel}=storeToRefs(component);
   
    onMounted(()=>{
        const colorState = {
            primaryColor: component.getSelectThemeColor,
        };
        ConfigProvider.config({
            theme: colorState,
        });
      document.documentElement.style.setProperty('--primary-color', component.getSelectThemeColor);
    })
  
</script>

<style lang="scss">

:root{
  --primary-color: '';
}
*{
  margin: 0;
  padding: 0;
}
.theme-light{
  @import "static/theme/light";
}
.theme-dark{
  color: #fefefe;
  @import "static/theme/dark";
}
@font-face {
  font-family: "siyuan"; /* Project id 3932765 */
  src: url('static/font/ecP_.woff')format('woff'),
  url('static/font/ecP_A.woff2')format('woff2')
}
#app {
  font-family: "siyuan",serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* 100%窗口高度 */
  height: 100vh;
  overflow: hidden;
  caret-color: transparent;
}
textarea,input{
  caret-color: #050505;
}
:deep(.iconfont){
  font-size: 20px;
}
.ant-modal div[aria-hidden="true"] {
  display: none !important
}
</style>
