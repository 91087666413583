import {createApp, } from 'vue'
import App from './App.vue'
import router from './router'
//引入图标
import "./static/css/iconfont.css"
//引入pinia
import store from './store'
const app = createApp(App);
import { Input,
    Layout,
    Form,
    Alert,
    Button,
    Tabs,
    Checkbox,
    Menu,
    Avatar,
    Row,
    Col,
    Space,
    Card,
    Skeleton,
    Select,
    Image,
    Collapse,
    Popover,
    Result,ConfigProvider,Breadcrumb,Table,Spin,Drawer,Dropdown,Switch,Modal } from 'ant-design-vue';


app.use(Input)
app.use(Layout)
app.use(Form)
app.use(Alert)
app.use(Button)
app.use(Tabs)
app.use(Checkbox)
app.use(Menu)
app.use(Avatar)
app.use(Image)
app.use(Result)
app.use(ConfigProvider)
app.use(Breadcrumb)
app.use(Table)
app.use(Spin)
app.use(Row).use(Col).use(Modal).use(Switch).use(Dropdown).use(Drawer).use(store).use(router).use(Select).use(Skeleton).use(Space).use(Collapse).use(Card).use(Popover)


app.directive("scrollToBottom",{
    updated(el){
        el.scrollTo({
            top:el.scrollHeight - el.clientHeight,
            behavior:"smooth"
        })
    }
})
//将component目录下所有vue组件注册
import component from './component';
// 遍历并注册组件
Object.entries(component).forEach(([name, component]) => {
    app.component(name, component);
});
app.mount('#app');

export default app;
