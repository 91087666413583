// components/index.js
const files = require.context('.', true, /\.vue$/);
const components = {};

files.keys().forEach((key) => {
    const component = files(key).default;
    const componentName = key.split('/').pop().replace(/\.\w+$/, '');
    components[componentName] = component;
});

export default components;