<template>
 <a-menu
     mode="inline"
     class="layoutMenu"
     :class=" [darkModel ? 'theme-dark':'theme-light'] "
 >
  <!--管理后台logo-->
   <div class="user-body">
     <a-image :src="logo" alt="网站logo" :preview="false" :width="40" :height="40"></a-image>
   </div>
  <!--侧边栏-->
   <a-menu v-for="item in userMenu" mode="inline" :selectedKeys="selectedKeys" :key="item.id" @select="selectMenu">
     <a-sub-menu v-if="item.children.length!==0" :key="item.menuName">
       <template #icon>
         <span :class="'icon-default '+item.icon" ></span>
       </template>
       <template #title>{{item.menuName}}</template>
  <!--是否由子路由-->
       <div v-for="(ch,index) in item.children"  :key="index" class="menu-item">
          <a-sub-menu v-if="ch.children.length!==0" :key="ch.menuName">
            <template #icon>
              <span  :class=" 'icon-default '+ch.icon"></span>
            </template>
            <template #title>{{ch.menuName}}</template>
            <a-menu-item v-for="son in ch.children" :key="son.menuName" @click="skipPage(son)"  >
              <template #icon>
                <span :class="'icon-son-default '+son.icon"></span>
              </template>
              {{son.menuName}}
            </a-menu-item>
          </a-sub-menu>
         <a-menu-item v-else :key="ch.menuName" @click="skipPage(ch)">
           <template #icon>
             <span :class="'icon-default '+ch.icon"></span>
           </template>
           {{ch.menuName}}
         </a-menu-item>
       </div>

     </a-sub-menu>
     <a-menu-item v-else :key="item.menuName" @click="skipPage(item)" >
       <template #icon>
         <span :class="'icon-default '+item.icon"></span>
       </template>
       {{item.menuName}}
     </a-menu-item>
   </a-menu>

 </a-menu>
</template>

<script>
import {onMounted} from 'vue'
import {ref,reactive} from 'vue'
import logo from "@/static/img/logo-0.png"
import {userStore} from "@/store/user";
import { storeToRefs } from 'pinia';
import {componentStore} from "@/store/component";
import router from "@/router";

export default {
  name: "menu-list",
  props:['routerKey'],
  setup(props,context){
    const {userMenu} =userStore()
    const component=componentStore()
    let {darkModel}=storeToRefs(component);
    let selectedKeys=ref(['首页'])
    onMounted(()=>{
      selectedKeys.value= findSelectedMenu(userMenu)
    })
    //查询菜单对应侧边栏
    function findSelectedMenu(menuList) {
      for (let i = 0; i < menuList.length; i++) {
        const menu = menuList[i];
        if (menu.path === router.currentRoute.value.path) {
          return [menu.menuName];
        }
        if (menu.children.length !== 0) {
          const result = findSelectedMenu(menu.children);
          if (result.length !== 0) {
            return result;
          }
        }
      }
      return [];
    }
    //跳转对应的路由地址
    function skipPage(skipPage) {
      router.push(skipPage.path)
    }

    function selectMenu(item, key, selected){
      selectedKeys.value=item.keyPath
      component.setRouterList(item.keyPath)
    }
    return{
      logo,
      userMenu,
      skipPage,
      selectMenu,
      selectedKeys,
      darkModel
    }
  },

}
</script>

<style scoped lang="scss">
.iconfont{
  font-size: 20px;
}
.user-body{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  max-width: 200px;
}
.icon-default{
  font-size: 18px !important;
}
.icon-son-default{
  font-size: 16px!important;
}
:deep(.ant-menu-title-content){
  text-align: left;
//  font-size: 20px;
}
.theme-light{
  @import "@/static/theme/light";
}
.theme-dark{
  @import "@/static/theme/dark";
}
</style>
