import { defineStore } from 'pinia'

// 写法1
export const componentStore = defineStore("component",{
    state: () => {
        return {
            //侧边栏是否缩放
            isCollapse: false,
            //面包屑导航路径
            routeList:['首页'],
            //侧边栏默认栏目
            selectItem:'',
            //是否暗黑模式
            darkModel:false,
            //选中主题样式
            selectThemeColor:'#ff4d4f'
        }
    },
    persist: {
        enabled: true,
        // 自定义持久化参数
        strategies: [
            {
                // 自定义key
                key: 'dark_model',
                // 自定义存储方式，默认sessionStorage
                storage: localStorage,
                // 指定要持久化的数据，默认所有 state 都会进行缓存，可以通过 paths 指定要持久化的字段，其他的则不会进行持久化。
                paths: ['darkModel'],
            },
            {
                // 自定义key
                key: 'select_theme_color',
                // 自定义存储方式，默认sessionStorage
                storage: localStorage,
                // 指定要持久化的数据，默认所有 state 都会进行缓存，可以通过 paths 指定要持久化的字段，其他的则不会进行持久化。
                paths: ['selectThemeColor'],
            }
        ]
    },
    getters: {
        getIsCollapse: (state) => {
            return state.isCollapse
        },
        getRouteList:(state)=>{
            return state.routeList
        },
        getSelectItem:(state)=>{
            return state.selectItem
        },
        getDarkModel:(state)=>{
            return state.drakModel
        },
        getSelectThemeColor:(state)=>{
            return state.selectThemeColor
        }
    },
    actions: {
        setIsCollapse() {
            this.isCollapse = !this.isCollapse
        },
        setRouterList(routeList){
            this.routeList = routeList
        },
        setSelectItem(selectItem){
            this.selectItem =selectItem
        },
        setDarkModel(drakModel){
            this.drakModel =drakModel
        },
        setSelectThemeColor(selectThemeColor){
            this.selectThemeColor =selectThemeColor
        }
    }
})

